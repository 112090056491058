import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from 'helpers/Firebase';
import { FIREBASE_TICKETSTOPROVIDERS_COLLECTION } from 'constants/defaultValuesFirebase';
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  getDoc,
  type DocumentData,
  Timestamp,
} from 'firebase/firestore';
import { isEmpty, handleUploadFiles } from 'helpers/genericTsHelpers';
import { FirebaseError } from 'firebase/app';
import { ProviderTicketStatus, type ProviderTicket } from 'types/Tickets';

interface ProviderTicketsState {
  tickets: ProviderTicket[];
  error: string;
  ticketsLoading: boolean;
}

const initialState: ProviderTicketsState = {
  tickets: [],
  error: '',
  ticketsLoading: true,
};

export const orderColumns = [
  { column: 'title', label: 'Title' },
  { column: 'category', label: 'Category' },
  { column: 'status', label: 'Status' },
];

const ConvertFirebaseDocumentToProviderTicket = (docu: DocumentData, id: string): ProviderTicket => {
  const {
    communityId,
    openerId,
    openerName,
    title,
    description,
    status,
    category,
    createDate,
    providerId,
    providerName,
    extraInfo,
  } = docu;
  const formattedCreateDate = createDate.toDate();
  const cdate = `${formattedCreateDate.getMonth() + 1}/${formattedCreateDate.getDate()}/${formattedCreateDate.getFullYear()}`;

  return {
    communityId,
    openerId,
    openerName,
    category,
    createDate: cdate,
    title,
    description,
    id,
    providerId,
    providerName,
    status,
    ...(extraInfo != null ? { extraInfo } : {}),
  };
};

interface GetProvidersTicketListParams {
  communityId?: string;
  openerId?: string;
  providerId?: string;
}

export const getProvidersTicketList = createAsyncThunk<
  ProviderTicket[],
  GetProvidersTicketListParams,
  { rejectValue: { error: string } }
>(
  'providersTickets/getProvidersTicketList',
  async ({ communityId, openerId, providerId }: GetProvidersTicketListParams, { rejectWithValue }) => {
    try {
      const ticketsRef = collection(db, FIREBASE_TICKETSTOPROVIDERS_COLLECTION);
      const filters = [];
      if (!isEmpty(communityId)) filters.push(where('communityId', '==', communityId));
      if (!isEmpty(openerId)) filters.push(where('openerId', '==', openerId));
      if (!isEmpty(providerId)) filters.push(where('providerId', '==', providerId));

      const q = query(ticketsRef, ...filters);
      const ticketsSnapshot = await getDocs(q);
      const documents = ticketsSnapshot.docs.map((docu) => {
        return ConvertFirebaseDocumentToProviderTicket(docu.data(), docu.id);
      });

      return documents;
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface AddProviderTicketParams {
  ticket: Omit<ProviderTicket, 'id' | 'status' | 'createDate'>;
  selectedFiles: File[];
}

export const addProviderTicket = createAsyncThunk<
  ProviderTicket,
  AddProviderTicketParams,
  { rejectValue: { error: string } }
>(
  'providerTickets/addProviderTicketItem',
  async ({ ticket, selectedFiles }: AddProviderTicketParams, { rejectWithValue }) => {
    try {
      const ticketsRef = collection(db, FIREBASE_TICKETSTOPROVIDERS_COLLECTION);
      const newTicket = {
        ...ticket,
        status: ProviderTicketStatus.PENDING,
        createDate: serverTimestamp(),
      };

      const newTicketRef = await addDoc(ticketsRef, newTicket);

      const newTicketId = newTicketRef.id;

      const docRefImages = doc(db, FIREBASE_TICKETSTOPROVIDERS_COLLECTION, newTicketId);

      // TODO: Do we need this? im pretty sure we generate this now on the fly
      if (selectedFiles.length > 0) {
        const newFieldData = {
          carpeta: `https://firebasestorage.googleapis.com/v0/b/thermokracy-dev.appspot.com/o/Tickets%2F${newTicketId}`,
        };
        await updateDoc(docRefImages, newFieldData);
        await handleUploadFiles(newTicketId, selectedFiles, FIREBASE_TICKETSTOPROVIDERS_COLLECTION);
      }

      return {
        ...newTicket,
        id: newTicketId,
        createDate:
          newTicket?.createDate instanceof Timestamp
            ? newTicket.createDate.toDate().toLocaleDateString()
            : new Date().toLocaleDateString(),
      } as const as ProviderTicket;
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

interface GetProviderTicketDetailsParams {
  ticketId: string;
}
export const getProviderTicketDetails = createAsyncThunk<
  ProviderTicket,
  GetProviderTicketDetailsParams,
  { rejectValue: { error: string } }
>('tickets/getProviderTicketDetails', async ({ ticketId }: GetProviderTicketDetailsParams, { rejectWithValue }) => {
  try {
    const ticketRef = doc(db, FIREBASE_TICKETSTOPROVIDERS_COLLECTION, ticketId);
    const ticketDoc = await getDoc(ticketRef);
    if (ticketDoc.exists()) {
      return ConvertFirebaseDocumentToProviderTicket(ticketDoc.data(), ticketDoc.id);
    }
    return rejectWithValue({ error: 'Ticket does not exist' });
  } catch (error: unknown) {
    if (error instanceof FirebaseError) {
      return rejectWithValue({ error: error.message });
    }
    return rejectWithValue({ error: 'An unknown error occurred' });
  }
});

interface UpdateProviderTicketFieldsParams {
  ticketIds: string;
  fieldsToUpdate: Partial<ProviderTicket>; // Partial allows updating one or more fields of the ticket
}

export const updateProviderTicketFields = createAsyncThunk<
  ProviderTicket,
  UpdateProviderTicketFieldsParams,
  { rejectValue: { error: string } }
>(
  'tickets/updateTicketFields',
  async ({ ticketIds: ticketId, fieldsToUpdate }: UpdateProviderTicketFieldsParams, { rejectWithValue }) => {
    try {
      const ticketDocRef = doc(db, FIREBASE_TICKETSTOPROVIDERS_COLLECTION, ticketId);

      await updateDoc(ticketDocRef, fieldsToUpdate);

      const ticketDocSnapshot = await getDoc(ticketDocRef);
      const ticket = ticketDocSnapshot.data();
      const updatedTicketDetails = { ...ticket, ...fieldsToUpdate };

      return ConvertFirebaseDocumentToProviderTicket(updatedTicketDetails, ticketId); // Assuming fixFirebaseData is a function that processes the data as needed
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        return rejectWithValue({ error: error.message });
      }
      return rejectWithValue({ error: 'An unknown error occurred' });
    }
  },
);

const providerTicketsSlice = createSlice({
  name: 'providerTickets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProvidersTicketList.pending, (state) => {
        state.ticketsLoading = true;
      })
      .addCase(getProvidersTicketList.fulfilled, (state, action) => {
        state.ticketsLoading = false;
        state.tickets = action.payload;
      })
      .addCase(getProvidersTicketList.rejected, (state) => {
        state.ticketsLoading = false;
      })
      .addCase(addProviderTicket.pending, (state) => {
        state.ticketsLoading = true;
      })
      .addCase(addProviderTicket.fulfilled, (state) => {
        state.ticketsLoading = false;
      })
      .addCase(addProviderTicket.rejected, (state) => {
        state.ticketsLoading = false;
      });
  },
});
export default providerTicketsSlice.reducer;
