import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FIREBASE_USERS_COLLECTION } from 'constants/defaultValuesFirebase';
import { collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from 'helpers/Firebase';
import { type HomeownerUser } from 'types/HomeownerUser';

const initialState: HomeownersState = {
  homeownersList: [],
  loading: false,
  error: null,
};

// Interfaces
interface HomeownersState {
  homeownersList: HomeownerUser[];
  loading: boolean;
  error: string | null;
}

interface GetHomeownersByArrayParams {
  homeownersArray: string[];
}

// Async Thunks
export const getHomeownerDetails = createAsyncThunk<
  HomeownerUser,
  { homeownerId: string },
  { rejectValue: { error: string } }
>('homeowners/getHomeownerDetails', async ({ homeownerId }, { rejectWithValue }) => {
  const details = await getDoc(doc(db, FIREBASE_USERS_COLLECTION, homeownerId));
  const data = details.data();
  if (data == null) {
    return rejectWithValue({ error: `Homeowner ${homeownerId} does not exists` });
  }
  return data as HomeownerUser;
});

export const getHomeownersByArray = createAsyncThunk<
  HomeownerUser[],
  GetHomeownersByArrayParams,
  { rejectValue: { error: string } }
>('homeowners/getHomeownersByArray', async ({ homeownersArray }, { rejectWithValue }) => {
  try {
    if (homeownersArray.length === 0) {
      return [];
    }
    const onlyIdsArray = homeownersArray.map((homeowner) => homeowner);
    const homeownersRef = collection(db, FIREBASE_USERS_COLLECTION);
    const q = query(homeownersRef, where('__name__', 'in', onlyIdsArray));
    const tempHomeownersArray = await getDocs(q);

    const homeownerList: HomeownerUser[] = tempHomeownersArray.docs.map((d) => {
      const data = d.data() as Omit<HomeownerUser, 'id'>;

      return {
        ...data,
        id: d.id,
      };
    });

    return homeownerList;
  } catch (error) {
    return rejectWithValue({ error: (error as Error).message });
  }
});

// Slice
const homeownersSlice = createSlice({
  name: 'homeowners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHomeownerDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHomeownerDetails.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getHomeownerDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch homeowner details';
      })
      .addCase(getHomeownersByArray.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHomeownersByArray.fulfilled, (state, action) => {
        state.loading = false;
        state.homeownersList = action.payload;
      })
      .addCase(getHomeownersByArray.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Failed to fetch homeowners';
      });
  },
});

export default homeownersSlice.reducer;
